import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import "./UVModal.css";
import { ListItemAvatar } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UVModal = ({
  modalTitle,
  leftAvatars,
  roles,
  disclaimer,
  url,
  open,
  openUrl,
  handleClose,
}: any) => {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="uv_modal"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{ m: 0, p: 2, fontFamily: "IBM Plex Serif", fontWeight: "600" }}
        minWidth={{ xs: 100, sm: 200 }}
        id="customized-dialog-title"
        className="uv_modal_title"
      >
        {modalTitle}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          spacing={{ xs: 2, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          minHeight={360}
        >
          <Grid
            xs={12}
            sm={4}
            md={4}
            display={{ xs: "none", md: "flex" }}
            justifyContent="center"
            alignItems="start"
            className="modal_left_panel medium_font"
          >
            <Item>
              <div>
                <List
                  sx={{
                    width: { xs: "100%" },
                    bgcolor: { xs: "background.paper" },
                  }}
                  className="uv_modal_list"
                >
                  {leftAvatars?.map((avatarObj: any, i: number) => (
                    <ListItem
                      disablePadding
                      key={avatarObj.name + i}
                      className="uv_modal_list_item"
                    >
                      <ListItemButton className="uv_modal_list_button">
                        <ListItemAvatar>
                          <Avatar alt={avatarObj.name} src={avatarObj.logo} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={avatarObj.name}
                          className="uv_modal_list_item_text"
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Item>
          </Grid>
          <Grid
            xs={12}
            sm={8}
            md={8}
            sx={{
              justifyContent: { xs: "center", md: "left" },
              alignItems: { xs: "center", md: "start" },
              padding: { xs: 0 },
            }}
            display="flex"
            alignItems="center"
            className="modal_right_panel medium_font"
          >
            <Item>
              <div>
                <List
                  sx={{
                    width: { xs: "100%" },
                    bgcolor: { xs: "background.paper" },
                  }}
                  className="uv_modal_list"
                >
                  {roles?.map((role: string, i: number) => (
                    <ListItem
                      disablePadding
                      key={role + i}
                      className="uv_modal_list_item"
                    >
                      <ListItemButton className="uv_modal_list_button">
                        <ListItemIcon
                          className="uv_modal_list_item_icon"
                          sx={{ minWidth: { xs: 40 } }}
                        >
                          <CheckIcon sx={{ fontSize: 16 }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={role}
                          className="uv_modal_list_item_text"
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Item>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {disclaimer && (
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontFamily: "IBM Plex Serif" }}
            className="uv_modal_disclaimer"
          >
            * {disclaimer}
          </Typography>
        )}

        {url && (
          <Button
            autoFocus
            onClick={openUrl}
            variant="contained"
            color="success"
            size="large"
            sx={{ fontSize: "1rem" }}
          >
            Visit Product
          </Button>
        )}
        <Button
          autoFocus
          onClick={handleClose}
          variant="contained"
          size="large"
          sx={{ fontSize: "1rem" }}
        >
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default UVModal;
