import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";

import componentData from "./home.json";
import "./home.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Home = () => {
  /***
   * @description Function to get total years of experience using your career start date.
   */
  const getTotalExp = (startMonth: number, startYear: number) => {
    var todayDate = new Date(),
      todayYear = todayDate.getFullYear(),
      todayMonth = todayDate.getMonth() + 1;
    var totalExp = todayYear - startYear;

    if (todayMonth < startMonth) {
      totalExp--;
    }

    return totalExp + " years";
  };
  return (
    <>
      <Grid
        xs={12}
        sm={4}
        md={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Item>
          <Avatar
            alt="Yuvraj Patil"
            src="assets/yuvraj-patil.jpg"
            sx={{
              display: "flex",
              width: { xs: "18rem", md: 350 },
              height: { xs: "18rem", md: 350 },
            }}
          />
        </Item>
      </Grid>
      <Grid
        xs={12}
        sm={8}
        md={8}
        sx={{
          justifyContent: { xs: "center", md: "left" },
        }}
        display="flex"
        alignItems="center"
      >
        <Item>
          <div className="home-text-container medium_font">
            <h1>
              Hi, I'm <strong> {componentData.creater}</strong>.
            </h1>
            <p>
              {componentData.descriptionPart1}
              <strong>{getTotalExp(10, 2012)}</strong>
              {componentData.descriptionPart2}
            </p>
            <br />

            <Button variant="contained" href="#about">
              {componentData.knowMoreButton}
            </Button>
          </div>
        </Item>
      </Grid>
    </>
  );
};
export default Home;
